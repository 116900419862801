export enum Team {
  WIJ,
  ZIJ,
}

export enum Operation {
  ADD,
  SUBTRACT,
}

export interface Point {
  score: number;
  pass?: boolean;
}

export interface Score {
  wij: Point[];
  zij: Point[];
}

export interface Animation {
  src: string;
  time: number;
  type: 'mp4' | 'gif';
}

export interface AnimationObj {
[key: string]: Animation;
}
