import styled from 'styled-components';

// TODO Add disabled style

export const Button = styled.button({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0px 15px 5px 0px',
  // minWidth: '200px',
  height: '30px',
  textAlign: 'center',
  border: '1px solid var(--color-dark-green)',
  borderRadius: '30px',
  padding: '5px 12px',
  backgroundColor: 'var(--color-dark-green)',
  color: 'var(--color-white)',
  fontSize: '1.4em',
  cursor: 'pointer',
  fontWeight: 500,
  outline: 'none',
  '&:hover': {
    opacity: 0.8,
  },
});

export const ButtonSmall = styled(Button)({
  minWidth: '150px',
});

export const ButtonGray = styled(Button)({
  backgroundColor: 'var(--color-light-gray)',
  color: 'var(--color-dark)',
  border: 'none',
});
