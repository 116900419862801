import React from 'react';
import styled from 'styled-components';
import { getSuitColor, getValue, getSuit } from '../helpers/cards';
import { Player } from '../models/Player';

interface Props {
  type: Player;
  name: string;
  troef?: string;
}

function getPlayerTransformation(player: Player, troef: string) {
  switch (player) {
    case Player.NORTH:
      return troef ? 'translateX(-18px)' : '';
    case Player.EAST:
      return 'rotate(90deg)';
    case Player.WEST:
      return 'rotate(-90deg)';
    default:
      return 'none';
  }
}

export const PlayerData = (props: Props) => (
    <Container player={props.type} troef={props.troef}>
      {props.name}
      {props.troef && (
        <>
          <TroefPlayerSuit src={getSuit(props.troef)} alt="troef" />
          <TroefPlayerValue card={props.troef}>{getValue(props.troef).toUpperCase()}</TroefPlayerValue>
        </>
      )}
    </Container>
);

const Container = styled.span((props: { player: Player; troef: string }) => ({
  position: 'absolute' as 'absolute',
  top: props.player === Player.NORTH ? '0.1em' : '40%',
  transform: getPlayerTransformation(props.player, props.troef),
  textAlign: 'center' as 'center',
  fontSize: '2em',
  right: props.player === Player.EAST ? '0' : 'auto',
  left: props.player === Player.WEST ? '0' : 'auto',
  width: props.player === Player.NORTH ? '100%' : 'auto',
}));

const TroefPlayerSuit = styled.img({
  position: 'absolute',
  width: '20px',
  margin: '0 5px',
  transform: 'translateY(8px)',
});

const TroefPlayerValue = styled.span((props: { card: string }) => ({
  position: 'absolute' as 'absolute',
  margin: '0 15px',
  transform: 'translateX(8px)',
  color: getSuitColor(props.card),
}));
