/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, forwardRef } from 'react';
import styled from 'styled-components';
import Draggable from 'react-draggable';
import { playingCardsList } from './PlayingCardsList';
import { getUniqueId } from '../../helpers/util';

const DOUBLE_PRESS_DELAY = 300;

interface Props {
  ref?: any;
  card: string;
  height: number;
  style: React.CSSProperties;
  zIndex: number;
  highlight?: boolean;
  flipped?: boolean;
  disableDragDrop?: boolean;
  onDrag?: (id: string) => void;
  onDragStop?: (id: string, e: any) => void;
  onDragStart?: (id: string) => void;
  onDoubleClick?: (id: string) => void;
}

const PlayingCard = (props: Props, ref: any) => {
  const initialCardData = {
    flipped: props.flipped || props.card === 'hide',
    card: props.card,
    height: props.height,
    style: props.style,
    initTransform: props.style.transform,
    position: { x: 0, y: 0 }, // TODO Try to remove
  };

  const [cardData, setCardData] = useState(initialCardData);
  const [lastClickTime, setLastClickTime] = useState(Date.now());
  const [dragData, setDragData] = useState({
    draggableDivStyle: { zIndex: props.zIndex, position: 'relative' as 'relative' },
  }); // Todo remove upper level

  useEffect(() => {
    console.log('Props changed!', props);
    setCardData(prevData => ({
      ...prevData,
      flipped: props.flipped,
      card: props.card,
      height: props.height,
      style: props.style,
      initTransform: props.style.transform,
      position: { x: 0, y: 0 }, // TODO Try to remove
    }));
  }, [props]);

  const handleDragStart = (e: any) => {
    setDragData({ draggableDivStyle: { zIndex: 999, position: 'relative' as 'relative' } });

    e.preventDefault(); // fixes desktop drag image issue

    if (cardData.style && cardData.style.transform) {
      if (cardData.style.transform.indexOf('rotate') !== -1) {
        const transform = cardData.style.transform.slice(0, -1); // copy it
        setCardData(prevData => ({
          ...prevData,
          style: { ...prevData.style, transform: transform.replace(/rotate(.*)/, 'rotate(0)') },
        }));
      }
    }
    props.onDragStart(cardData.card);
  };

  const handleDrag = () => {
    props.onDrag(cardData.card);
  };

  const handleDragStop = (e: any) => {
    // setTimeout(function() {
    //     this.state.draggableDivStyle = //{"transitionDuration": "0.25s",
    //         {}
    // }, 100)
    // this.state.draggableDivStyle = {"transitionDuration": "1s"}
    if (cardData.style && cardData.style.transform) {
      if (cardData.style.transform.indexOf('rotate') !== -1) {
        setCardData(prevData => ({
          ...prevData,
          style: { ...prevData.style, transform: cardData.initTransform },
        }));
      }
      // let newStyle = {transform :  this.state.style.transform.replace(/rotate(.*)/, 'rotate(0)')};
    }

    // reset zIndex after drag
    setDragData({ draggableDivStyle: { zIndex: props.zIndex, position: 'relative' as 'relative' } });
    props.onDragStop(cardData.card, e);
  };

  const handleClick = () => {
    const now = Date.now();
    if (now - lastClickTime < DOUBLE_PRESS_DELAY) {
      props.onDoubleClick(cardData.card);
      console.log('doubled');
    }
    setLastClickTime(Date.now());
  };

  return (
    <Draggable
      onStart={handleDragStart} // bind this from PlayingCard
      onStop={handleDragStop}
      onDrag={handleDrag}
      disabled={!!props.disableDragDrop}
      enableUserSelectHack={true}
      position={cardData.position} // resets back to initial position on drag end
    >
      <div onClick={handleClick} onTouchStart={handleClick} style={dragData.draggableDivStyle} className={getUniqueId()}>
        <Card
          ref={ref}
          style={cardData.style}
          highlight={props.highlight}
          height={cardData.height}
          className="Playing-card"
          src={cardData.flipped === true ? playingCardsList.flipped : playingCardsList[cardData.card]}
          alt={cardData.flipped === true ? 'Hidden Card' : playingCardsList[cardData.card]}
        />
      </div>
    </Draggable>
  );
};

function getBoxShadow(highlight?: boolean) {
  return highlight ? '2px 2px 15px gold, -2px -2px 15px gold' : '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)';
}

const Card = styled.img((props: { style?: any; highlight: boolean }) => ({
  ...props.style,
  boxShadow: getBoxShadow(props.highlight),
  transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
  borderRadius: '4px',
  position: 'absolute',
  border: '1px solid rgba(0,0,0,0.25)',
  backfaceVisibility: 'hidden', // fix for jumping
  '&:hover': {
    boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
  },
}));

export default forwardRef(PlayingCard);
