import * as React from 'react';
import styled from 'styled-components';
import { MainTitle, FlexWrappedRow, Button } from '../components/styled';

export const Landing = () => (
    <Container>
      <MainTitle>Troef 2.0</MainTitle>
      <FlexWrappedRow>
        <Button>Scorebord</Button>
      </FlexWrappedRow>
      <FlexWrappedRow>
        <Button>Speel tegen vrienden</Button>
      </FlexWrappedRow>
      <FlexWrappedRow>
        <Button>Ik heb geen vrienden</Button>
      </FlexWrappedRow>
    </Container>
);

const Container = styled.div({
  display: 'block',
  margin: 'auto',
  '@media only screen and (min-width: 1281px) and (min-height: 800px)': {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
  },
});
