import styled from 'styled-components';

export const Link = styled.a({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-start',
  cursor: 'pointer',
  color: 'var(--color-green)',
  '&:hover': {
    color: 'var(--color-dark-green)!important',
    opacity: 0.7,
  },
  '&:focus': {
    color: 'var(--color-dark-green)!important',
    opacity: 0.7,
  },
});

export const LinkGray = styled.a({
  color: 'var(--color-gray)',
});
