import React, { useState, useCallback, useRef, useLayoutEffect, useEffect } from 'react';

import { Reclame } from '../components/styled';
import { Container, VideoContainer, NameLeft, NameRight, ScoreGrid, ScoreLeft, ScoreNumber, ScoreRight,
  ActionsLeft, ActionButton, ActionsRight, Middle, ActionButtonRow, Video } from '../components/styled/Scoreboard';
import { ToolBar } from '../components/ToolBar';
import { getUniqueId, removeLast } from '../helpers/util';
import { Animation, AnimationObj, Point, Score, Team } from '../models/Scoreboard';


const movs: AnimationObj = {
  explosion: {
    time: 860,
    src: require('../assets/images/explosion.gif'),
    type: 'gif',
  },
  tetten: {
    time: 2000,
    src: require('../assets/movs/gewonnenx.mp4'),
    type: 'mp4',
  },
  extraTetten: {
    time: 3100,
    src: require('../assets/movs/gewonnenx2.mp4'),
    type: 'mp4',
  },
};

export const ScoreBoard = () => {
  useEffect(() => {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore:next-line
      navigator.wakeLock.request('screen');
      console.log('Screen Wake Lock is active');
    } catch (err) {
      console.error(err);
    }
  }, []);

  const scoreRef = useRef(null);

  const [score, setScore] = useState<Score>({
    wij: [{ score: 10 }],
    zij: [{ score: 10 }],
  });

  const [pass, setPass] = useState<boolean>(false);
  const [kapot, setKapot] = useState<boolean>(null);
  const [alles, setAlles] = useState<boolean>(null);
  const [animation, setAnimation] = useState<Animation | null>(null);

  const showAnimation = (anim: Animation) => {
    setAnimation(anim);
    setTimeout(() => {
      setAnimation(null);
    }, (anim.time * 2) + 100);
  };


  // use layout effect here -> scroll(dom update) before rerender
  useLayoutEffect(() => {
    scoreRef.current.scrollTop = scoreRef.current.scrollHeight;
  }, [score, pass]);

  const updateScore = useCallback(
    (team: Team) => {
      if (getLastScore(score.wij) < 1 || getLastScore(score.zij) < 1) {
        return;
      }

      const winnerDeduction = getDeductionValue(pass, alles);
      const loserAddition = kapot ? 1 : 0;

      let wij: Point[];
      let zij: Point[];
      let pendingAnimation = false;

      if (team === Team.WIJ) {
        wij = getUpdatedScoreArray(score.wij, winnerDeduction);
        zij = getUpdatedScoreArray(score.zij, loserAddition);
      }

      if (team === Team.ZIJ) {
        wij = getUpdatedScoreArray(score.wij, loserAddition);
        zij = getUpdatedScoreArray(score.zij, winnerDeduction);
      }


      if (getLastScore(wij) <= 0 || getLastScore(zij) <= 0) {
        if (Math.abs(getLastScore(wij) - getLastScore(zij)) >= 9) {
          pendingAnimation = true;
          showAnimation(winnerDeduction < -2 ? movs.extraTetten : movs.tetten);
        }
      }

      if (kapot) {
        if (!pendingAnimation) showAnimation(movs.explosion);
        setKapot(false);
      }

      if (pass) setPass(false);
      if (alles) setAlles(false);

      setScore({ wij, zij });
    },
    [pass, score, kapot, alles]
  );

  const togglePass = () => {
    const wij = [...score.wij];
    const zij = [...score.zij];

    const lastIndex = score.wij.length - 1;
    const newPassValue = !pass;

    wij[lastIndex].pass = newPassValue;
    zij[lastIndex].pass = newPassValue;

    setScore({ wij, zij });
    setPass(newPassValue);
  };

  const toggleAlles = () => {
    setAlles(!alles);
  };

  const toggleKapot = () => {
    setKapot(!kapot);
  };

  const undo = () => {
    if (score.wij.length < 2) {
      return;
    }
    const wij = removeLast<Point>(score.wij);
    const zij = removeLast<Point>(score.zij);

    setScore({ wij, zij });
    setPass(zij[zij.length - 1].pass);
  };

  const resetGame = () => {
    setPass(false);
    setScore({
      wij: [{ score: 10 }],
      zij: [{ score: 10 }],
    });
  };

  return (
    <Container>
      <ToolBar />
      {animation && (
        <VideoContainer>
          {animation.type === 'gif' && (
            <img src={animation.src} alt="animation"/>
          )}
           {animation.type === 'mp4' && (
            <Video autoPlay={true}>
              <source src={animation.src} type="video/mp4" />
            </Video>
           )}
        </VideoContainer>
      )}
      <NameLeft>Wij</NameLeft>
      <NameRight>Zij</NameRight>

      <ScoreGrid ref={scoreRef}>
        <ScoreLeft>
          {score.wij.map(value => (
            <ScoreNumber pass={!!value.pass} side={Team.WIJ} key={getUniqueId()}>
              {value.score}
            </ScoreNumber>
          ))}
        </ScoreLeft>
        <ScoreRight>
          {score.zij.map(value => (
            <ScoreNumber pass={!!value.pass} side={Team.ZIJ} key={getUniqueId()}>
              {value.score}
            </ScoreNumber>
          ))}
        </ScoreRight>
      </ScoreGrid>
      <ActionsLeft>
        <ActionButton
          onClick={() => updateScore(Team.WIJ)}
        >
          Slag
        </ActionButton>
      </ActionsLeft>
      <ActionsRight>
        <ActionButton
          onClick={() => updateScore(Team.ZIJ)}
        >
          Slag
        </ActionButton>
      </ActionsRight>
      <Middle>
        <ActionButtonRow>
          <ActionButton activated={alles} onClick={toggleAlles}>Alles</ActionButton>
          <ActionButton activated={pass} onClick={togglePass}>Passenspel</ActionButton>
          <ActionButton activated={kapot} onClick={toggleKapot}>Kapot</ActionButton>
        </ActionButtonRow>
        <ActionButtonRow>
          <ActionButton onClick={undo}>Foutje</ActionButton>
          <ActionButton onClick={resetGame}>Oppernieuw</ActionButton>
        </ActionButtonRow>
      </Middle>
      <Reclame />
    </Container>
  );
};

function getUpdatedScoreArray(teamScore: Point[], value: number) {
  const lastScore = getLastScore(teamScore);
  const updatedScore = lastScore + value;

  return [...teamScore, { score: updatedScore }];
}

function getLastScore(teamScore: Point[]) {
  const lastScoreIndex = teamScore.length - 1;

  return teamScore[lastScoreIndex].score;
}

function getDeductionValue(pass: boolean, alles: boolean) {
  if (pass && alles) return -3;
  if (pass || alles) return -2;

  return -1;
}
