let lastId = 0;

export function getUniqueId(prefix = 'id') {
  lastId += 1;
  return `${prefix}-${lastId}`;
}

export function replace(source: any[], index: number, item: any) {
  return [...source.slice(0, index), item, ...source.slice(index + 1)];
}

export function removeLast<T>(source: T[]) {
  return [...source.splice(0, source.length - 1)];
}

export function removeElementAtIndex<T>(source: T[], index: number) {
  return [...source.slice(0, index), ...source.slice(index + 1)];
}

export function isDefined(value: any) {
  return value !== null && value !== undefined;
}

export function getRandomProperty(obj: object) {
  const keys = Object.keys(obj);
  return obj[keys[getRandomInt(0, keys.length - 1)]];
}

export function getRandomInt(min: number, max: number, excludedNumbers: number[] = []) {
  let randomInt;
  do {
    randomInt = Math.floor(Math.random() * (max - min + 1)) + min; // The maximum and the minimum are inclusive
  } while (excludedNumbers.includes(randomInt));

  return randomInt;
}

export function getRandomIntArray(min: number, max: number, arrayLength: number, excludedNumbers: number[] = [], unique = false) {
  const randomIntArray = [];
  if (unique && max - min < arrayLength) {
    throw new Error('Cannot create unique random array with');
  }
  while (randomIntArray.length < arrayLength) {
    const randomInt = getRandomInt(min, max, unique ? excludedNumbers : []);
    excludedNumbers.push(randomInt);
    randomIntArray.push(randomInt);
  }
  return randomIntArray;
}

export function rotateArray(array: any[], steps: number) {
  if (steps === 0) return array;

  return [...array.slice(steps, array.length), ...array.slice(0, steps)];
}
