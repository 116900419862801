import * as React from 'react';
import styled from 'styled-components';
import backIos from '../assets/icons/back.svg';

export const ToolBar = () => (
    <Container>
      <BackButton src={backIos} alt="Back Logo" />
    </Container>
);

const Container = styled.div({
  width: '100%',
  height: '40px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const BackButton = styled.img({
  filter: 'invert(1)',
  height: '75%',
  alignSelf: 'start',
});
