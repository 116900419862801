import React, { useState, useEffect } from 'react';
import PlayingCard from './playingCard/PlayingCard';
import { getRandomIntArray, getUniqueId, rotateArray } from '../helpers/util';
import { Player } from '../models/Player';

const MAX_CARDS_ON_FIELD = 4;

interface Props {
  cards: string[];
  cardHeight: number;
  startPlayer: Player;
}

export const PlayField = (props: Props) => {
  const [cards, setCards] = useState<string[]>(props.cards);
  const [cardTransformations] = useState(getCardTransformations(props.startPlayer));
  // const [uniqueKeys] = useState(Array(MAX_CARDS_ON_FIELD).fill(getUniqueId('card')));

  useEffect(() => {
    setCards(props.cards);
  }, [props.cards]);

  useEffect(() => {
    console.error('Rendering Play Field');
  });

  return (
    <>
      {cards.map((card: string, i: number) => (
          <PlayingCard
            key={getUniqueId('card')}
            height={props.cardHeight}
            card={card}
            style={{ transform: cardTransformations[i] }}
            zIndex={i}
            flipped={false}
            disableDragDrop={true}
          />
      ))}
    </>
  );
};

export function getCardTransformations(startPlayer: Player) {
  const offsets = getRotatedRandomCardOffsets(startPlayer);
  const rotations = getRandomCardRotations();

  const transformations = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < MAX_CARDS_ON_FIELD; i++) {
    transformations.push(`${offsets[i]} ${rotations[i]}`);
  }

  return transformations;
}

export function getRotatedRandomCardOffsets(startPlayer: Player) {
  return rotateArray(getRandomCardOffsets(), startPlayer);
}

export function getRandomCardOffsets() {
  const minOffset = 3;
  const maxOffset = 4;

  const randomOffsets = getRandomIntArray(minOffset, maxOffset, MAX_CARDS_ON_FIELD);
  const translations = ['translateY(-', 'translateX(', 'translateY(', 'translateX(-'];

  return randomOffsets.map((cardOffset, i) => `${translations[i]}${cardOffset}em)`);
}

function getRandomCardRotations() {
  const minRotation = 3;
  const maxRotation = 5;

  const randomCardRotations = getRandomIntArray(minRotation, maxRotation, MAX_CARDS_ON_FIELD);

  // const evenSign = Math.random() < 0.5 ? '-' : '';
  // const oddSign = evenSign === '-' ? '' : '-';
  // return randomCardRotations.map((cardRotation, i) => `rotate(${i % 2 === 0 ? evenSign : oddSign}${cardRotation}deg)`);

  return randomCardRotations.map(cardRotation => `rotate(${Math.random() < 0.5 ? '-' : ''}${cardRotation}deg)`);
}
