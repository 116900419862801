/* eslint-disable no-return-assign */
import React, { useState, useRef, useEffect, useMemo } from 'react';
import PlayingCard from './playingCard/PlayingCard';
import { getUniqueId } from '../helpers/util';
import { calculateCoords, getWidthMultiplier, getDeadCardStyle } from '../helpers/cards';

interface Props {
  cards: string[];
  deadCards: string[];
  cardHeight: number;
  layout: string;
  troef?: string;
  onCardDrop?: (id: string, x: number, y: number) => void;
  onDoubleClick?: (id: string) => void;
}

export const Hand = (props: Props) => {
  const fanStyles = useMemo(() => getFanStyles(props.cards, props.deadCards, props.cardHeight), [props.cards, props.deadCards, props.cardHeight]);

  const [cards, setCards] = useState<string[]>(props.cards);
  const [cardStyles, setCardStyles] = useState<any[]>(fanStyles);
  const [containerStyle, setContainerStyle] = useState<React.CSSProperties>({
    marginLeft: 'auto',
    marginRight: 'auto',
    width: `${props.cardHeight * getWidthMultiplier(cards.length)}px`,
  });
  const cardRefs = useRef([]);

  useEffect(() => {
    console.log('Cards / Dead Cards update');
    setContainerStyle({
      marginLeft: 'auto',
      marginRight: 'auto',
      width: `${props.cardHeight * getWidthMultiplier(cards.length - props.deadCards.length)}px`,
    });
  }, [cards, props.deadCards]);

  useEffect(() => {
    console.log('Init Hand, setting refs');
    console.assert(Array.isArray(props.cards), 'Hands must have cards, even as an empty array');
    console.log('refs already available? ', cardRefs);
    const coords = calculateCoords(cards.length, cardRefs.current[0].width);
    console.log('Got Coords? ', coords);
  }, []);

  useEffect(() => {
    console.error('Prop card update!!');
    console.log(props.deadCards);
    setCards(props.cards);
    setCardStyles(fanStyles);
  }, [props.deadCards, props.cardHeight]);

  useEffect(() => {
    console.error('Rendering Hand');
  });

  const handleDragStop = (id: string, e: any) => {
    setCardStyles(cardStyles);

    if (e instanceof TouchEvent) {
      console.log('TouchEvent!');
      const changedTouch = e.changedTouches[0];
      if (changedTouch) {
        props.onCardDrop(id, changedTouch.clientX, changedTouch.clientY);
      }
    } else if (e instanceof MouseEvent) {
      console.log('Mouse Event!');
      props.onCardDrop(id, e.clientX, e.clientY);
    }
  };

  const handleDrag = (id: string) => {
    console.log('dragging: ', id);
  };

  const handleDragStart = (id: string) => {
    console.log('HAND - handling drag Start: ', id);
  };

  const handleDoubleClick = (id: any) => {
    props.onDoubleClick(id);
  };

  return (
    <div className={'Hand'} style={containerStyle}>
      {cards.map((card: string, i: number) => (
          <PlayingCard
            key={getUniqueId('card')}
            onDragStart={handleDragStart}
            onDragStop={handleDragStop}
            // highlight={props.troef === card}
            highlight={i === 1}
            onDoubleClick={handleDoubleClick}
            onDrag={handleDrag}
            ref={el => (cardRefs.current[i] = el)}
            height={props.cardHeight}
            card={card}
            style={cardStyles[i]}
            zIndex={i}
          />
      ))}
    </div>
  );
};

function getFanStyles(cards: string[], deadCards: string[], cardHeight: number) {
  const aliveCards = cards.length - deadCards.length;
  if (aliveCards < 1) {
    return cards.map(() => getDeadCardStyle());
  }

  const coords = calculateCoords(aliveCards, cardHeight);

  const fanStyles = coords.map(coord => ({
    left: `${coord.x}px`,
    top: `${coord.y}px`,
    position: 'absolute',
    transform: `rotate(${coord.angle}deg)`,
    display: 'block',
  }));

  const mappedFanStyles = cards.map((card: string) => {
    if (deadCards.includes(card)) {
      return getDeadCardStyle();
    }
    const firstElement = fanStyles[0];
    fanStyles.shift();
    return firstElement;
  });

  console.log('returning mapped styles:', mappedFanStyles);
  return mappedFanStyles;
}
