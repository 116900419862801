import styled from 'styled-components';

export const Small = styled.small({
  fontSize: '15px',
  fontWeight: 100,
  color: 'var(--color-gray)',
});

export const SmallJustified = styled(Small)({
  '@media only screen and (max-width: 768px)': {
    textAlign: 'justify',
  },
});
