import styled from 'styled-components';
import { Team } from '../../models/Scoreboard';

export const border = '3px solid white';
export const fontSize = '1.6em';
export const sideMarginTeams = '5px';
export const sideMarginScores = '5px';

export const Container = styled.div({
  width: '100%',
  height: '100%',
  display: 'grid',
  gridTemplateColumns: '50% 50%',
  gridTemplateRows: '40px min-content auto min-content 150px 100px',
  backgroundColor: 'var(--color-background)',
  textTransform: 'uppercase',
  color: 'white',
  fontFamily: 'Helvetica',
});


export const ScoreGrid = styled.div({
  width: '100%',
  maxHeight: '100%',
  display: 'grid',
  gridTemplateColumns: '50% 50%',
  gridTemplateRows: 'auto',
  backgroundColor: 'var(--color-background)',
  textTransform: 'uppercase',
  color: 'white',
  fontFamily: 'Helvetica',
  gridRow: 3,
  gridColumn: '1 / span 2',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    width: 0,
  },
  whiteSpace: 'nowrap',
  scrollbarWidth: 'none',
  WebkitOverflowScrolling: 'touch',
});

export const Teams = styled.div({
  textAlign: 'center',
  borderBottom: border,
  fontSize: '1.7em',
  fontWeight: 'bold',
});

export const Scores = styled.div({
  paddingTop: '10px',
  paddingBottom: '5px',
  textAlign: 'center',
  fontSize,
});

export const ScoreNumber = styled.div((props: { pass?: boolean; side?: Team }) => ({
  fontSize,
  lineHeight: 1,
  position: 'relative' as 'relative',
  '&::before': {
    top: '100%',
    position: 'absolute' as 'absolute',
    width: '71%',
    left: props.side === Team.WIJ ? '30%' : 0,
    right: props.side === Team.ZIJ ? '30%' : 0,
    height: props.pass ? '2px' : 0,
    content: '""',
    backgroundColor: 'white',
  },
}));

export const NameLeft = styled(Teams)({
  gridRow: 2,
  gridColumn: 1,
  borderRight: border,
  marginLeft: sideMarginTeams,
});

export const NameRight = styled(Teams)({
  gridRow: 2,
  gridColumn: 2,
  marginRight: sideMarginTeams,
});

export const ScoreLeft = styled(Scores)({
  gridRow: 1,
  gridColumn: 1,
  borderRight: border,
  paddingLeft: sideMarginScores,
});

export const ScoreRight = styled(Scores)({
  gridRow: 1,
  gridColumn: 2,
  marginRight: sideMarginScores,
});

export const Actions = styled.div({
  marginTop: '10px',
});

export const ActionsLeft = styled(Actions)({
  gridRow: 4,
  gridColumn: 1,
  justifySelf: 'center',
});

export const ActionsRight = styled(Actions)({
  gridRow: 4,
  gridColumn: 2,
  justifySelf: 'center',
});

export const ActionButton = styled.span((props: { activated?: boolean }) => ({
  backgroundColor: props.activated ? 'white' : 'var(--color-dark-green)',
  color: props.activated ? 'var(--color-dark-green)' : 'white',
  opacity: 0.9,
  // width: '50px',
  margin: '2px 10px 2px 10px',
  paddingLeft: '10px',
  paddingRight: '10px',
  display: 'inline-block',
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.8,
  },
  border: '1px solid var(--color-dark-green)',
  borderRadius: '30px',
  fontWeight: 500,
  userSelect: 'none' as const,
  textAlign: 'center' as const,
  textTransform: 'none' as const,
  fontSize,
}));
/* export const VSpacer = styled.p({
  display: 'block',
}); */

/* export const HSpacer = styled.p({
  display: 'inline-block',
  width: '20px',
}); */

export const Middle = styled.div({
  gridRow: 5,
  gridColumn: '1 / span 2',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

export const ActionButtonRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '5px',
});

export const VideoContainer = styled.div`
  position: absolute;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  align-items: center;
  justify-content: center;
  object-fit: fill;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  min-width: 100%;
  z-index: 9000;
  display: none;
  @media (pointer:coarse) {
    display: block;
  }
  `;

export const Video = styled.video({
  // objectFit: 'fill',
  minWidth: '100%',
  minHeight: '100%',
  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: '100% !important',
  height: 'auto !important',
});
