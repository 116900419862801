export function vhToPx(value: number) {
  const w = window;
  const d = document;
  const e = d.documentElement;
  const g = d.getElementsByTagName('body')[0];
  const y = w.innerHeight || e.clientHeight || g.clientHeight;

  const result = (y * value) / 100;
  return result;
}

/*
export function vwToPx(value) {
  var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth,
    y = w.innerHeight|| e.clientHeight|| g.clientHeight;

  var result = (x*value)/100;
  document.getElementById("result_vw_px").innerHTML = result;  // affichage du résultat (facultatif)
  return result;
}

function pxTOvw(value) {
  var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth,
    y = w.innerHeight|| e.clientHeight|| g.clientHeight;

  var result = (100*value)/x;
  document.getElementById("result_px_vw").innerHTML = result;  // affichage du résultat (facultatif)
  return result;
}

function pxTOvh(value) {
  var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth,
    y = w.innerHeight|| e.clientHeight|| g.clientHeight;

  var result = (100*value)/y;
  document.getElementById("result_px_vh").innerHTML = result;  // affichage du résultat (facultatif)
  return result;
}
*/
