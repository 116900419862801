import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './assets/styles/style.css';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import 'react-s-alert/dist/s-alert-default.css';
import { ScoreBoard, Game } from './pages';

function App() {
  return (
    <Router basename="/">
      <Alert stack={{ limit: 3 }} />
      <Switch>
        <Route exact path="/" render={(props: any) => <ScoreBoard {...props} />} />
        <Route exact path="/play" render={(props: any) => <Game {...props} />} />
      </Switch>
    </Router>
  );
}

export default App;
