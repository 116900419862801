import React, { useRef, useCallback, useState } from 'react';
import styled from 'styled-components';
import { Hand } from '../components/Hand';
import { PlayerData } from '../components/PlayerData';
import { vhToPx } from '../helpers/screen';
import { getCardWidth, getRandomTroefCards } from '../helpers/cards';
import { PlayField } from '../components/PlayField';
import { Player } from '../models/Player';
import { getRandomProperty } from '../helpers/util';

const layout = 'fan';
const suits = {
  clubs: require('../assets/icons/clubs.svg'),
  diamonts: require('../assets/icons/diamonts.svg'),
  hearts: require('../assets/icons/hearts.svg'),
  spades: require('../assets/icons/spades.svg'),
}; // Can be removes, for demo puprose now

export const Game = () => {
  const playFieldRef = useRef(null);
  const [startingPlayer] = useState(getRandomStartingPlayer());
  const [handCards] = useState(getRandomTroefCards());
  const [deadCards, setDeadCards] = useState([]);

  const isInDropzone = useCallback(
    (x: number, y: number) => {
      if (!playFieldRef?.current) {
        console.error('No playfield ref');
        return false;
      }
      const boundingRect = playFieldRef.current.getBoundingClientRect();

      const cardHeight = getCardHeight();
      const cardHeightExtraMargin = cardHeight * 0.66;

      const cardWidth = getCardWidth(cardHeight);
      const cardWidthExtraMargin = cardWidth / 2;

      const minX = boundingRect.x - cardWidthExtraMargin;
      const maxX = boundingRect.x + cardWidth + cardWidthExtraMargin;

      const minY = boundingRect.y;
      const maxY = boundingRect.y + cardHeight + cardHeightExtraMargin;

      console.log('x, y', x, y);
      console.log('minX, maxX', minX, maxX);
      console.log('minY, maxY', minY, maxY);

      return x > minX && x < maxX && y > minY && y < maxY;
    },
    [playFieldRef]
  );

  const handleCardDrop = (id: string, x: number, y: number) => {
    // console.log(`Card dragged: ${id} on on position: ${x} - ${y}`);
    // console.log(playFieldRef.current.getBoundingClientRect());
    const hasDropZoneCollision = isInDropzone(x, y);
    if (hasDropZoneCollision) {
      setDeadCards(prev => [...prev, id]);
    }
    console.log(`is In dropzone? ${hasDropZoneCollision}`);
  };

  const handleCardDoubleClick = (id: string) => {
    setDeadCards(prev => [...prev, id]);
  };

  return (
    <Container>
      <PlayerData type={Player.NORTH} name="North" troef="1s" />
      <PlayerData type={Player.EAST} name="East" troef="jh" />
      <PlayerData type={Player.WEST} name="West" troef="10c" />
      <TopRow />
      <MidRow ref={playFieldRef}>
        <PlayField cards={deadCards} cardHeight={getCardHeight()} startPlayer={startingPlayer} />
      </MidRow>
      <Hand
        layout={layout}
        troef={'9h'}
        deadCards={deadCards}
        cards={handCards}
        cardHeight={getCardHeight()}
        onCardDrop={handleCardDrop}
        onDoubleClick={handleCardDoubleClick}
      />
      <TroefSuit src={getRandomProperty(suits)} alt="troef" />
    </Container>
  );
};

function getCardHeight() {
  return vhToPx(25);
}

function getRandomStartingPlayer() {
  const startingPlayers = [Player.NORTH, Player.EAST, Player.SOUTH, Player.WEST];
  const randomIndex = Math.floor(Math.random() * startingPlayers.length);

  return startingPlayers[randomIndex];
}

const Container = styled.div({
  width: '100%',
  height: '100%',
  display: 'grid',
  gridTemplateColumns: '100%',
  gridTemplateRows: '15% 50% 35%',
  backgroundColor: 'var(--color-background)',
  color: 'white',
  fontFamily: 'Helvetica',
});

const TopRow = styled.div({
  gridRow: 1,
  gridColumn: '1',
});

const MidRow = styled.div({
  gridRow: 2,
  gridColumn: '1',
  justifySelf: 'center',
  alignContent: 'center',
  paddingTop: '4%',
  // backgroundColor: 'green',
  width: getCardWidth(getCardHeight()),
});

const TroefSuit = styled.img({
  position: 'absolute',
  bottom: '16px',
  left: '16px',
});
