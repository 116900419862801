import styled from 'styled-components';

export const Title = styled.div({
  fontSize: '25px',
  fontWeight: 300,
  marginBottom: '20px',
  '@media only screen and (max-width: 944px)': {
    marginBottom: '5px',
  },
});

export const MainTitle = styled.h1({
  textAlign: 'center',
  marginBottom: '20px',
  fontWeight: 100,
});
