import styled from 'styled-components';

export const FlexCol = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '5px',
  margin: '0px 20px',
  width: '450px',
  '@media only screen and (max-width: 1025px)': {
    margin: '0 auto',
  },
});

export const FlexRow = styled.div({
  display: 'flex',
  justifyContent: 'center',
  padding: '10px',
});

export const FlexWrappedRow = styled(FlexRow)({
  flexWrap: 'wrap',
});

export const FlexStart = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-start',
});

export const SpaceBetween = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'baseline',
});

export const Center = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
});
