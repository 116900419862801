/* eslint-disable no-plusplus */
import { degreesToRadians, getRotatedDimensions, radiansToDegrees } from './math';

export const NUMBER_OF_HAND_CARDS = 4;

// How much to show between cards, expressed as percentage of textureWidth
const CARD_SPACING = 0.44;

// This is the radius of the circle under the fan of cards and thus controls the overall curvature of the fan. Small values means higher curvature
const ARC_RADIUS = 144;

const WIDTH_MULTIPLIERS = [1, 1.5, 2.156, 2.7];

const CARD_RATIO = 18 / 25;

const DEAD_CARD_STYLE = {
  left: '-100px',
  top: '-100px',
  position: 'absolute',
  transform: 'none',
  display: 'none',
};

const SUITS = {
  clubs: require('../assets/icons/clubs.svg'),
  diamonds: require('../assets/icons/diamonts.svg'),
  hearts: require('../assets/icons/hearts.svg'),
  spades: require('../assets/icons/spades.svg'),
};

export function calculateCoords(numCards: number, cardHeight: number) {
  // The separation between the cards, in terms of rotation around the circle's origin
  const coords: Coords[] = [];
  const cardWidth = cardHeight * CARD_RATIO;

  const anglePerCard = radiansToDegrees(Math.atan((cardWidth * CARD_SPACING) / ARC_RADIUS));
  const angleOffset = 270;
  const startAngle = angleOffset - 0.5 * anglePerCard * (numCards - 1);

  let minX = 99999;
  let minY = 99999;
  let maxX = -minX;
  let maxY = -minY;

  for (let i = 0; i < numCards; i++) {
    const rawDegrees = startAngle + anglePerCard * i;

    const radians = degreesToRadians(rawDegrees);
    const x = cardWidth / 2 + Math.cos(radians) * ARC_RADIUS;
    const y = cardHeight / 2 + Math.sin(radians) * ARC_RADIUS;
    const degrees = rawDegrees + 90 - 360;

    minX = Math.min(minX, x);
    minY = Math.min(minY, y);
    maxX = Math.max(maxX, x);
    maxY = Math.max(maxY, y);

    coords.push({ x, y, angle: degrees });
  }

  const rotatedDimensions = getRotatedDimensions(coords[0].angle, cardWidth, cardHeight);

  let offsetX = 0;
  let offsetY = 0;

  offsetX = minX * -1;
  offsetX += (rotatedDimensions[0] - cardWidth) / 2;

  offsetY = minY * -1;

  return coords.map(coord => ({
    x: Math.round(coord.x + offsetX),
    y: Math.round(coord.y + offsetY),
    angle: Math.round(coord.angle),
  }));
}

export function getWidthMultiplier(index: number) {
  return CARD_RATIO * WIDTH_MULTIPLIERS[index - 1];
}

export function getDeadCardStyle() {
  return DEAD_CARD_STYLE;
}

export function getCardWidth(cardHeight: number) {
  return cardHeight * CARD_RATIO;
}

export function getTroefDeck() {
  const suits = ['c', 'd', 'h', 's'];
  const values = ['9', '10', 'j', 'q', 'k', '1'];

  const selectedCards = [];

  for (const value of values) {
    for (const suit of suits) {
      selectedCards.push(`${value}${suit}`);
    }
  }

  return selectedCards;
}

export function getRandomTroefCards() {
  const cards = getTroefDeck();
  const randomCards = [];

  while (randomCards.length < NUMBER_OF_HAND_CARDS) {
    const randomIndex = Math.floor(Math.random() * cards.length);
    randomCards.push(cards[randomIndex]);
    cards.splice(randomIndex, 1);
  }

  return randomCards;
}

export function getSuitColor(card: string) {
  if (card.endsWith('c') || card.endsWith('s')) {
    return 'black';
  }
  return 'red';
}

export function getSuit(card: string) {
  if (card.endsWith('c')) return SUITS.clubs;
  if (card.endsWith('d')) return SUITS.diamonds;
  if (card.endsWith('h')) return SUITS.hearts;
  if (card.endsWith('s')) return SUITS.spades;

  return SUITS.spades;
}

export function getValue(card: string) {
  return card.slice(0, -1);
}

interface Coords {
  x: number;
  y: number;
  angle: number;
}
