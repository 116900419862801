/* eslint-disable no-restricted-syntax */

export interface PlayingCardList {
  [key: string]: string;
}

export const playingCardsList: PlayingCardList = {};

const suits = ['c', 'd', 'h', 's'];
const faces = ['j', 'q', 'k'];

const addSuits = (i: number | string) => {
  for (const suit of suits) {
    playingCardsList[i + suit] = require(`./CardImages/${i}${suit}.svg`);
  }
};

for (let i = 1; i <= 10; i += 1) {
  addSuits(i);
}

for (const i of faces) {
  addSuits(i);
}

playingCardsList.flipped = require('./CardImages/b.svg');
