export function degreesToRadians(degrees: number) {
  return degrees * (Math.PI / 180);
}

export function radiansToDegrees(radians: number) {
  return radians * (180 / Math.PI);
}

export function getRotatedDimensions(angleInDegrees: number, width: number, height: number) {
  const angle = (angleInDegrees * Math.PI) / 180;
  const sin = Math.sin(angle);
  const cos = Math.cos(angle);
  const x1 = cos * width;
  const y1 = sin * width;
  const x2 = -sin * height;
  const y2 = cos * height;
  const x3 = cos * width - sin * height;
  const y3 = sin * width + cos * height;
  const minX = Math.min(0, x1, x2, x3);
  const maxX = Math.max(0, x1, x2, x3);
  const minY = Math.min(0, y1, y2, y3);
  const maxY = Math.max(0, y1, y2, y3);

  return [Math.floor(maxX - minX), Math.floor(maxY - minY)];
}

export function rotatePointInBox(x: number, y: number, angle: number, width: number, height: number) {
  const angleInRadians = degreesToRadians(angle);

  const centerX = width / 2.0;
  const centerY = height / 2.0;
  const dx = x - centerX;
  const dy = y - centerY;
  const dist = Math.sqrt(dx * dx + dy * dy);
  const a = Math.atan2(dy, dx) + angleInRadians;
  const dx2 = Math.cos(a) * dist;
  const dy2 = Math.sin(a) * dist;

  return [dx2 + centerX, dy2 + centerY];
}
